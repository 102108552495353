<template>
  <div class="card h-100 card-white">
    <div
      class="card-body m-info"
      @mouseleave="showFav = false"
      @mouseover="showFav = true"
    >
      <div class="m-info-left">
        <div class="model-info-header d-flex w-100 align-items-start">
          <div class="model-info-image me-4 img_hover" @click="showImg = true">
            <img
              :src="data.modelImage"
              @error="replaceByDefaultImg"
              alt="Model name"
            />
            <i aria-hidden="true">
              <ph-arrows-out :size="32" color="#f99a9a" />
            </i>
          </div>
          <div class="flex-grow-1 d-flex flex-column justify-content-between">
            <div class="d-flex justify-content-between">
              <div class="model-number">
                <div
                  class="d-flex justify-content-center align-items-center gap-3"
                >
                  <CopyButton
                    :text="getVAN(data.supplierId, data.modelId)"
                    :text-class="'desc text-xs text-color-primary ms-1 font-500'"
                  />
                </div>
                <span class="desc text-xs text-color-primary ms-1 font-500">{{
                  `(${pad(data.supplierId, 6)}-${pad(data.modelId, 4)})`
                }}</span>
              </div>
              <div class="cursor-pointer" style="width: 30px">
                <star-component
                  v-if="showFav || isFav"
                  ref="star"
                  :isFavorite="isFav"
                  listingType="table"
                  @onFav="onFav"
                />
              </div>
            </div>
            <span class="model-title text-sm font-500"
              >Modell: {{ data.modelName }}</span
            >
          </div>
        </div>
        <div class="model-info-footer d-flex m-info-b">
          <div class="item">
            <div class="item-left">
              <img src="/imgs/dummy_model_icon.png" alt="" />
            </div>
            <div class="item-right">
              <span class="desc">Warengruppe</span>
              <span class="title">{{
                data.goodsGroup ? data.goodsGroup.split(' ')[0] : ''
              }}</span>
            </div>
          </div>
          <div
            class="item"
            @click="
              $router.push({
                path: `/${
                  $route.name === 'sellerCatalogDetail' ? 'seller' : 'buyer'
                }/models?seller=${data.supplierName}&id=${data.supplierId}`
              })
            "
          >
            <div class="item-left">
              <img src="/imgs/dummy_logo.png" alt="" />
            </div>
            <div class="item-right">
              <span class="title text-sm">{{ data.supplierName }}</span>
            </div>
          </div>
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center mt-3"
        >
          <div v-if="resPerson.fullName">
            <p class="mb-0 text-xs">{{ `${resPerson.fullName}` }}</p>
          </div>
          <div v-else>
            <p class="text-center mb-0 text-xs">
              Kein Ansprechpartner gepflegt.
            </p>
          </div>
          <div v-if="resPerson.fullName" class="person-info-title">
            <p class="mb-0">Einkauf</p>
          </div>
        </div>
      </div>
      <div class="m-info-right">
        <div class="model-info-footer m-info-r d-flex w-100 pl-4">
          <div
            class="item-right"
            v-if="showTzText(modelInfo) && !isInterliving"
          >
            <span class="title text-sm text-color-primary">{{
              getTzText(modelInfo)
            }}</span>
          </div>
          <div class="item-right" v-if="data.status">
            <span class="desc">Status:</span>
            <span
              class="title text-sm"
              :class="`text-color-${
                data.status === 'inaktiv' ? 'danger' : 'success'
              }`"
              >{{ data.status }}</span
            >
          </div>
          <div class="item-right" v-if="modelInfo.PreisbindungVon">
            <span class="desc">Gültig ab</span>
            <span class="title text-sm">{{ modelInfo.PreisbindungVon }}</span>
          </div>
          <div class="item-right" v-if="modelInfo.PreisbindungBis">
            <span class="desc">Gültig bis</span>
            <span class="title text-sm">{{ modelInfo.PreisbindungBis }}</span>
          </div>
          <div class="item-right">
            <span class="desc">Zuletzt beabeitet</span>
            <span class="title text-sm">-</span>
          </div>
        </div>
        <div class="btn-currency" @click="changeRouteSystem">
          <ph-gear weight="bold" :size="24" color="#ee7f00" />
        </div>
        <div class="btn-currency" @click="changeRoute">
          <ph-currency-eur weight="bold" :size="24" color="#ee7f00" />
        </div>
      </div>
      <transition name="fade">
        <ImageViewer
          v-if="showImg && data.modelImages.length > 0"
          @close="showImg = false"
          :imageArray="data.modelImages"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import starComponent from '../common/starComponent.vue'
import { PhCurrencyEur, PhArrowsOut, PhGear } from 'phosphor-vue'
import ImageViewer from '../common/ImageView.vue'
import CopyButton from '../ui/CopyButton.vue'

export default {
  components: {
    PhCurrencyEur,
    PhGear,
    ImageViewer,
    PhArrowsOut,
    starComponent,
    CopyButton
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {}
    },
    modelInfo: {
      type: Object,
      required: true,
      default: () => {}
    },
    isInterliving: {
      type: Boolean,
      default: false
    },
    resPerson: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      showImg: false,
      showFav: false,
      isFav: this.modelInfo.isFavorite
    }
  },
  computed: {
    pathEinkauf() {
      return this.$route.name === 'buyerCatalogDetail' ? 'seller' : 'buyer'
    },
    pathEinkaufOrigin() {
      return this.$route.name === 'buyerCatalogDetail' ? 'buyer' : 'seller'
    },
    pathSystem() {
      return 'system'
    },
    favType() {
      return this.$store.getters.getFavoriteType
    }
  },
  methods: {
    replaceByDefaultImg(e) {
      e.target.src = '/imgs/missing_photo.svg'
    },
    changeRoute() {
      this.$router.push({
        path: `/${this.pathEinkauf}/model/${this.$route.params.id}`,
        query: { supplierId: this.$route.query.supplierId }
      })
    },
    changeRouteSystem() {
      this.$router.push({
        path: `/${this.pathSystem}/${this.$route.query.supplierId}/${this.$route.params.id}/${this.pathEinkaufOrigin}`
      })
    },
    async onFav(e, post) {
      try {
        let url = ''
        if (e === this.favType) {
          this.isFav = post
          this.showFav = post
          this.$refs.star.icoType = post ? 'fill' : 'thin'
          url = post ? '' : '/delete'
        }
        await this.$api.setFavorite(url, {
          type: 'model',
          access: e,
          modelId: this.data.modelId,
          supplierId: this.data.supplierId
        })
      } catch (e) {
        console.error(e)
      }
    },
    showTzText(item) {
      return item.tz || this.hasTeuerungszuschlagInfo(item)
    },
    hasTeuerungszuschlagInfo(item) {
      return (
        item.TeuerungszuschlagInProzent ||
        item.TeuerungszuschlagInformation ||
        item.TeuerungszuschlagGültigVon ||
        item.TeuerungszuschlagGültigBis
      )
    },
    getTzText(item) {
      if (item.tz === true) {
        return 'PL - inkl. TZ kalkuliert'
      }
      if (item.tz === false && this.hasTeuerungszuschlagInfo(item)) {
        return 'PL, ohne TZ kalkuliert bitte TZ-Informationen beachten'
      }
      if (!item.tz && this.hasTeuerungszuschlagInfo(item)) {
        return 'TZ-Infos im Modell beachten'
      }
      return ''
    },
    getVAN(supp, mod) {
      return `${this.pad(supp, 6)}${this.pad(mod, 4)}`
    },
    pad(num, size) {
      num = num.toString()
      while (num.length < size) num = '0' + num
      return num
    }
  },
  name: 'ModelInfo'
}
</script>
<style scoped lang="scss">
.img_hover {
  text-align: center;
  cursor: pointer;
  i {
    position: absolute;
    border-radius: 50%;
    font-size: 34px;
    width: 60px;
    height: 60px;
    line-height: 50px;
    background: #ffffff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    -webkit-transform: scale(0);
    transform: scale(0);
    transition: all 300ms 0ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  &:hover {
    img {
      opacity: 0.7;
    }
    i {
      -webkit-transform: scale(1);
      transform: scale(1);
      transition: all 300ms 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
  }
}

.model-number {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.font-500 {
  font-weight: 500;
}

.btn-currency {
  cursor: pointer;
  border-radius: 6px;
  background-color: white;
  padding: 0.5rem;
  width: fit-content;
  height: fit-content;
  margin: 0 10px;
}

.btn-currency:hover {
  background-color: #ebebeb;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.card.card-white {
  background-color: white;

  .model-info-image {
    background-color: white;
  }
}
</style>
